import MailchimpSubscribe from "react-mailchimp-subscribe"
import './MailingList.css';

function MailingList() {
  return (
    <main className='MailingList'>
      <p>
        Sign up to the mailing list to stay up to date with all the latest developments, such as:
      </p>
      <ul>
        <li>Release Notes</li>
        <li>Roadmap Updates</li>
        <li>Developer Comments</li>
      </ul>
      <MailchimpSubscribe url={"https://racctuality.us12.list-manage.com/subscribe/post?u=dcf805ff6af9e94a8dd7fe519&amp;id=2678cdc800&amp;f_id=00c142e0f0"}/>
    </main>
  );
}

export default MailingList;
