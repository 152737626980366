import { Link } from 'react-router-dom';
import './Home.css';
import logo_httc from "./hail_to_the_chief/logo_httc.png"

function Home() {
  return (
    <main className='Home'>
      <Link to='hail-to-the-chief' className='Home-Content'>
        <img src={logo_httc} className='Home-ContentImage' type="image/png" alt="The logo of the game titled 'Hail to
        the Chief', which consists of the name of the game superimposed on a blue, white, and red background. A single
        five-pointed start lies between the words 'to' and 'the'." />
        <p className='Home-ContentCaption'>
          Hail to the Chief - Early Access TBC
        </p>
      </Link>
    </main>
  );
}

export default Home;
