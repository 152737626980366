import './About.css';

function About() {
  return (
    <main className='About'>
      <p>
        Racctuality is a small indie game development studio based in the United Kingdom. Currently, the studio is quite
        a small affair, run by just one man. That's me! Though there's not much here at the moment, I hope that over
        time I can share my passion for development here by creating fun and well-polished games for all to enjoy.
      </p>
      <br/>
      <p>
        Currently, my efforts are focused entirely on Hail to the Chief, a round-based nation-building strategy game
        where you can take charge of your own fledgling settlement and transform it into a prosperous and powerful
        country. Though still early in development, I hope to be able to show you more about this exciting project
        very soon.
      </p>
    </main>
  );
}

export default About;
