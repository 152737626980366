import '../DeveloperLogPost.css';

function Post() {
  return (
    <main className='Post'>
      <h1>Vision</h1>
      <section className='Post-Date'>
        <em>15th March 2024</em>
      </section>
      <h2>WELCOME</h2>
      <p>
        I'm very excited to finally be sharing my latest project with the world, but before I go into any further
        detail I'd like to firstly thank all of you who have taken an interest in following my progress. If you're
        reading this, that means you as well! I hope that I'll be able to use this space to provide entertaining and
        informative insights into my journey through the world of game development, and that it may allow you to fell
        closer to the end product as a result.
        <br/><br/>
        In this first entry, I'm going to be writing about my vision for Hail to the Chief. This will include the more
        obvious things like my plans for gameplay features, but also will touch on some of the more philosophical
        aspects of the process too. Rather than just telling everyone <em>what</em> decisions I make, I want people to
        understand <em>why</em> I'm making those decisions.
      </p>
      <h2>GAME STYLE</h2>
      <p>
        Hail to the Chief is intended to be a round-based nation-building strategy game. That's the tagline, if there
        was one. Of course, I recognise that such a description is hardly very meaningful without further elaboration,
        so I will discuss what I mean by each descriptor.
        <br/><br/>
        Firstly, the strategy aspect is perhaps the broadest of those, but it's the one that can arguably get away with
        being broad. Strategy presents itself in so many forms, and many of those may well feature in Hail to the Chief.
        In general, I want the game to be one where the player can make meaningful and powerful choices to affect the
        state of the game. I also want there to be a wide range of viable choices one can make, avoiding a situation
        where there is a clearly-defined metagame and only one way to be competitive. Of course, in practice these
        patterns can be quite hard to nail down, but my hope is that the breadth of different interplaying systems in
        the game will make it difficult to identify any one superior playstyle.
        <br/><br/>
        I continue to describe Hail to the Chief as a nation-building game. This reflects the 'plot' of the game, in the
        sense that the player will take on a leadership role over a populace and will then be able to build up that
        community from a humble beginning to a fully-fledged nation state. I want the player to be able to have fine
        tuning over many aspects of this, so that they can feel that the country they craft is truly in their desired
        image. While one could choose to treat the game as nothing more than a game of strategy, I want the option to
        exist for players to treat it like more of a role-playing game if they so desire. This will manifest in many
        ways, but ranges from strategic decisions concerning policy (diplomatic, economic, technological, military etc.)
        to more aesthetic choices like the name of the country or the design of its flag.
        <br/><br/>
        I have also described Hail to the Chief as 'round-based'. I'm sure you will recognise that this is hardly
        common parlance, with games typically being termed either turn-based or real-time. If I had to pick one of those
        then I would say the game will be turn-based, but it will be a little more complex than that. The reality is
        that I find myself a little bit stuck between the two - I want the player to be able to respond dynamically and
        simultaneously with others to events in a way that a turn-based system doesn't always lend itself readily to,
        but I also don't want the game to turn into a competition of 'who can click the fastest' which a real-time
        approach would risk. As a result I've essentially chosen a system where the game is played in 'rounds', each
        of which is then split into smaller stages. The players will decide on their desired actions to take, and then
        once all players are ready, the actions will play out with only limited player input. I hope that it will make
        sense when you experience it.
      </p>
      <h2>GAMEPLAY FEATURES</h2>
      <p>
        As it stands, Hail to the Chief is still in very active early development. As a result of this, I can't give you
        an exact list of features that will definitely be included in the first release, or even the full release. With
        that said, I <em>can</em> share some of the features which are currently planned, in one form or another, to be
        eventually included (just bear in mind this is subject to change):
        <ul>
          <li>Customisable nations, including:
            <ul>
              <li>
                A choice of national origins and historic ideologies, which will offer different playstyles from the
                very beginning.
              </li>
              <li>A custom flag designer.</li>
              <li>
                Choice of a national tune from a selection of popular folk and patriotic melodies, which will be
                reflected in background and incidental music.
              </li>
            </ul>
          </li>
          <li>
            A number of policy areas which will allow the player to choose from several approaches, with new policies
            becoming available as technology progresses and new national issues arise.
          </li>
          <li>
            An event system which can throw up surprises at every turn and which can have a range of consequences
            depending on the player's choices.
          </li>
          <li>
            A research and technology system which will allow players to tune their long-term strategy.
          </li>
          <li>
            A military system offering a wide range of units to fit a range of strategic playstyles, but which is
            designed to remains accessible and avoids becoming overwhelmingly complicated through over-reliance on
            micro-management.
          </li>
          <li>
            An elaborate economic simulation combined with a resource system which can create opportunities for both
            cooperation and conflict between players.
          </li>
          <li>
            A detailed diplomatic system which will allow players to interact with other nations in a multitude of ways,
            including:
            <ul>
              <li>
                Forming alliances and pacts between nations, from simple bipartisan agreements to NATO-style security
                groups.
              </li>
              <li>Creating economic and trade unions.</li>
              <li>Forming UN-style worldwide organisations and voting on joint diplomatic actions.</li>
            </ul>
          </li>
          <li>
            A range of available government types, with fully-implemented election systems for democratic governments,
            including campaigning and inter-party debates.
          </li>
          <li>
            Simulated natural systems such as weather and natural disasters to allow for even more dynamic events.
          </li>
          <li>Both singleplayer and multiplayer gameplay.</li>
          <li>A range of customisable game rules to allow players to play the game in their favourite way.</li>
        </ul>
      </p>
      <h2>COMMERICAL DIRECTION</h2>
      <p>
        As my final topic in this post, I want to address the more business-like decisions I have already made regarding
        the game. While I am (at the time of writing) just one person making a game, I have known from the very start
        that I want to do things my own way, and in some cases in spite of the direction of the industry. I believe that
        the larger entertainment companies, who are responsible for so many games today, have shown themselves to be
        greedy, cold, and indifferent to consumer opinion. Not only do I think this is the wrong way to treat people,
        but I think it's just bad business. Needless to say, I won't be doing things this way.
        <br/><br/>
        One of the glaringly obvious ways I'm trying to deviate from a 'standard' is in my choice of the Godot Engine
        for developing Hail to the Chief. Much of my previous experience was with Unity, but after the fiasco with the
        Unity Runtime changes, I realised that it was frankly dangerous from a commerical perspective to allow an
        unaffiliated company to have so much control over my product. For this reason and more, it became clear to me
        that an open-source solution like Godot was not just advisable but practically necessary. Thankfully, it's
        also <em>really</em> good. Of course there are little issues here and there, as is the nature of open-source
        software, but on the whole it's been a really fun experience so far. A little different to Unity, sure, but not
        in a bad way at all - and let's not pretend Unity was without its own little issues here and there! For all
        their contributions, I give my thanks now to those who have made Godot what it is today.
        <br/><br/>
        Another important issue for me is the topic of value for money. A lot of games these days are very expensive if
        you want the 'full' experience, what with all the various bits of DLC that usually entails, and there's also a
        particular problem with Early Access games that people can pay a lot of money for a product they never actually
        receive. I want to make sure that my own products are as honest and fair as they can be. As such, I'm committed
        firstly to not releasing any gameplay features behind an additional paywall once you've already bought the game.
        That means no DLC except for purely non-gameplay content such as soundtracks. Secondly, I am committed to
        pricing the game at a fair price for the content it has when you buy it, <em>not</em> for the content it might
        have in the future. While there may be occasions where this becomes a little less clear, such as sales or other
        discounts, I will try my best to act in the right spirit.
      </p>
      <h2>IN CONCLUSION</h2>
      <p>
        I do hope this has been an interesting first insight into the development journey of Hail to the Chief. I'm
        hopeful that I will be able to share these updates fairly regularly, in conjunction with the behind-the-scenes
        content available on my other social platforms. If you happen to have any questions about anything I've written
        here, please do feel free to get in touch, either via email, social media, or on the Racctuality Discord (all
        linked below).
        <br/><br/>
        Thanks for reading,
        <br/>
        Racctuality
      </p>
    </main>
  );
}

export default Post;