import { Link } from 'react-router-dom';
import './Footer.css';
import logo_discord from "./logo_discord.svg"
import logo_instagram from "./logo_instagram.svg"
import logo_tiktok from "./logo_tiktok.png"

function Footer() {
  return (
    <main className='Footer'>
      <section className='Footer-Logos'>
        <a href="https://discord.gg/8u8FJpBqkV">
          <img src={logo_discord} className='Footer-Logo' type="image/svg" alt="The Discord logo."/>
        </a>
        <a href="https://www.instagram.com/racctuality/">
          <img src={logo_instagram} className='Footer-Logo' type="image/svg" alt="The Instagram logo."/>
        </a>
        <a href="https://www.tiktok.com/@racctuality/">
          <img src={logo_tiktok} className='Footer-Logo' type="image/png" alt="The Tiktok logo."/>
        </a>
      </section>
      <p>
        Contact: <a href="mailto:enquiries@racctuality.co.uk">
          enquiries@racctuality.co.uk
        </a>
        <br/>
        Sign up to the <Link to="mailing-list">
          mailing list
        </Link> to stay updated.
        <br/>
        © Racctuality™ 2024 - All rights reserved.
      </p>
    </main>
  );
}

export default Footer;
