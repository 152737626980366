import { Outlet } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import './Layout.css';

function Layout() {
  return (
    <main className="Layout">
      <section className="Layout-Wrapper">
        <NavigationBar />
        <Outlet />
      </section>
      <Footer />
    </main>
  );
}

export default Layout;
