import { Link } from 'react-router-dom';
import './NavigationBar.css';
import logo from './logo.png';

function NavigationBar() {
  return (
    <main className='NavigationBar'>
      <section className='NavigationBar-LogoBox'>
        <img src={logo} className='NavigationBar-Logo' type="image/png" alt="The full Racctuality logo - a drawing of a
        raccoon with the word 'racctuality' underneath, with letters in alternating colours to mimic the pattern of a
        raccoon's tail."/>
      </section>
      <nav className='NavigationBar-Options'>
        <Link to=''>Home</Link>
        <Link to='about'>About</Link>
        <div className='NavigationBar-Dropdown'>
          <button className='NavigationBar-Dropdown-Button'>
            <Link to='hail-to-the-chief'>Hail to the Chief ▼</Link>
          </button>
          <div className='NavigationBar-Dropdown-Content'>
            <Link to='hail-to-the-chief/developer-log'>Developer Log</Link>
          </div>
        </div>
      </nav>
    </main>
  );
}

export default NavigationBar;
