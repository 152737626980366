import { Link } from 'react-router-dom';
import './HailToTheChief.css';
import logo_httc from "./logo_httc.png"
import teaser_trailer from "./teaser_trailer.mp4"

function HailToTheChief() {
  return (
    <main className='HailToTheChief'>
      <section className='HailToTheChief-TitleBar'>
        <img src={logo_httc} className='HailToTheChief-Image' type="image/png" alt="The logo of the game titled 'Hail to
        the Chief', which consists of the name of the game superimposed on a blue, white, and red background. A single
        five-pointed start lies between the words 'to' and 'the'." />
        <section className='HailToTheChief-TitleBar-Content'>
          <h1>
            HAIL TO THE CHIEF
          </h1>
          <p>
            Hail to the Chief is a round-based nation-building strategy game where you can take charge of your own
            fledgling settlement and transform it into a prosperous and powerful country!
          </p>
          <section className='HailToTheChief-TitleBar-Content-Buttons'>
            <Link to="../mailing-list" className='HailToTheChief-TitleBar-Content-Buttons-Activated'>
              Subscribe to the mailing list to be kept up-to-date with all the latest news.
            </Link>
            <Link to="./developer-log" className='HailToTheChief-TitleBar-Content-Buttons-Activated'>
              Read the Developer Log to learn more about what goes on behind the scenes.
            </Link>
          </section>
          <section className='HailToTheChief-TitleBar-Content-Buttons'>
            <div className='HailToTheChief-TitleBar-Content-Buttons-Deactivated'>
              Check out Hail to the Chief on the Steam Store <br/>(COMING SOON)
            </div>
          </section>
        </section>
      </section>
      <video controls>
        <source src={teaser_trailer} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
      <section className='HailToTheChief-Features'>
        <h1>
          GAMEPLAY FEATURES
        </h1>
        <p>
          <em>
            Please note that while all the following features are planned in some form, some of these listed features may
            not be included in the initial Early Access release, owing to the rapidly-shifting landscape of early
            development. It is however intended that all will be present in the eventual full release (Version 1.0.0).
          </em>
        </p>
        <ul>
          <li>
            <strong>LEAD YOUR NATION - </strong> Customise your nation's origins, both political and cultural, then
            guide it from its origins as a nascent settlement to becoming a powerful and prosperous country.
          </li>
          <li>
            <strong>MAKE MEANINGFUL CHOICES - </strong> A suite of policy options and a large assortment of dynamic
            events will allow you no shortage of options in how you lead your country, through challenge and through
            celebration you will be able to carve your own path in your world's history.
          </li>
          <li>
            <strong>RAISE A POWERFUL MILITARY - </strong> Choose from a wide range of military units to compose your
            nation's army. Whether to serve as imposing defenders or methodical invaders, the choice is yours!
          </li>
          <li>
            <strong>BECOME AN ECONOMIC POWERHOUSE - </strong> Build up a strong industrial base and leverage your
            economic power with an elaborate economic simulation.
          </li>
          <li>
            <strong>FORGE GREAT ALLIANCES AND BITTER RIVALRIES - </strong> A detailed diplomatic system will allow the
            story of your own nation to be interwoven with those of the rest of the world. From forming powerful pacts
            with your allies to promising destruction to your enemies, you can place yourself in the bustling centre of
            a complex diplomatic web.
          </li>
          <li>
            <strong>PLAY ALONE OR WITH FRIENDS - </strong> With both singleplayer and multiplayer modes available to
            choose from, you can enjoy the same gameplay whether it's by yourself or with a group of friends.
          </li>
          <li>
            <strong>MAKE THE EXPERIENCE YOUR OWN - </strong> A variety of customisation options will allow you to
            tweak your gameplay experience to your desires, allowing you to enjoy the game to the fullest extent possible.
          </li>
        </ul>
      </section>
      <section className='HailToTheChief-Features'>
        <h1>
          ADDITIONAL INFORMATION
        </h1>
        <ul>
          <li>
            <strong>BUY ONCE, PLAY FOREVER - </strong> I am committed to a policy that ensures those who purchase my
            games are entitled to any future updates, never locking future gameplay content behind a paywall for a game
            you have already purchased. So long as the choice is mine to make, I will never release paid gameplay DLC
            for my games. <em>(The only exception to this rule will be if ever I release purely non-gameplay content,
            for example concept art or a soundtrack.)</em>
          </li>
          <li>
            <strong>GET WHAT YOU PAY FOR - </strong> I believe that you should never have to pay for a promise. While
            this game remains in Early Access, I will endeavour to price it fairly according to its value at the time of
            sale, and not according to what it might one day become. Instead, I will raise the price over time, as I
            continue to update the game. I will always aim to give adequate warning of upcoming price rises so as not to
            catch anyone out. While this does not always account for sales or other discounts, in general I will do my
            best to ensure that I am not taking money for something I am yet to deliver.
          </li>
          <li>
            <strong>ENGAGE WITH THE DEVELOPMENT JOURNEY - </strong> Community engagement is very important to me, and I
            welcome feedback and suggestions from everyone. While I have a strong vision for Hail to the Chief, I also
            understand that there's no point sticking completely to a vision if nobody wants to see the result. As such,
            though I make no promises I can act on all that I hear, I encourage you to get involved and let me know your
            opinions using Racctuality's social platforms.
          </li>
        </ul>
      </section>
    </main>
  );
}

export default HailToTheChief;
