import './Error404.css';

function Error404() {
  return (
    <main className='Error404'>
      <h1>
        <strong>404 - Not Found</strong>
      </h1>
      <p>
        We're sorry, but it appears the page you're looking for doesn't exist.
      </p>
      <p>
        It might have been renamed, moved elsewhere, or deleted.
      </p>
      <p>
        <a href='/'>Click here to return to the Home page.</a>
      </p>
    </main>
  );
}

export default Error404;
